import { gql } from "@apollo/client";

export const USER_SNAPSHOT_FRAGMENT = gql`
  fragment UserSnapshot_Fragment on UserSnapshot {
    __typename
    id
    name
    jobTitle
    companyName
    avatar
    location
    pronouns
    displayPronouns
    joinedAt
  }
`;

export const USER_FOLLOW_DATA_FRAGMENT = gql`
  fragment User_Follow_Data_Fragment on User {
    followingGroups(first: 999) {
      edges {
        cursor
        node {
          id
          slug
          name
          description
          deletedAt
          imageUrl
          totalFollowers
          type
          leadership {
            user {
              id
            }
            role
            capabilities
          }
          followers(first: 5) {
            edges {
              node {
                id
                name {
                  formatted
                }
                snapshot {
                  avatar
                }
              }
            }
          }
        }
      }
    }
    followingUsers(first: 999) {
      edges {
        cursor
        node {
          id
          type
          snapshot {
            ...UserSnapshot_Fragment
          }
        }
      }
    }
    followerUsers(first: 999) {
      edges {
        cursor
        node {
          id
          type
          snapshot {
            ...UserSnapshot_Fragment
          }
        }
      }
    }
  }
  ${USER_SNAPSHOT_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment User_Fragment on User {
    id
    snapshot {
      ...UserSnapshot_Fragment
    }
    type
    memberLeader
  }
  ${USER_SNAPSHOT_FRAGMENT}
`;

export const USER_WITH_FOLLOW_DATA_FRAGMENT = gql`
  fragment User_With_Follow_Data_Fragment on User {
    id
    ...User_Follow_Data_Fragment
  }
  ${USER_FOLLOW_DATA_FRAGMENT}
`;

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile_Fragment on UserProfile {
    avatar
    bio
    company {
      name
      description
      industry
      employeeCount
      website
      areasOfFocus
      social {
        facebook
        instagram
        linkedIn
        twitter
      }
    }
    displayPronouns
    jobTitle
    location {
      code
      familiarName
      name
      placeId
    }
    pronouns
    publicUrl
    skills
    social {
      facebook
      instagram
      linkedIn
      twitter
    }
    website
  }
`;

export const USER_PHONE_FRAGMENT = gql`
  fragment UserPhone_Fragment on UserPhone {
    id
    countryCode
    number
    extension
  }
`;

export const USER_SUPPORT_CONTACT_FRAGMENT = gql`
  fragment UserSupportContact_Fragment on UserSupportContact {
    id
    name
    mobilePhone {
      ...UserPhone_Fragment
    }
    officePhone {
      ...UserPhone_Fragment
    }
    email
  }
  ${USER_PHONE_FRAGMENT}
`;
