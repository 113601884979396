/* eslint-disable react/no-unstable-nested-components */
import { Paper, Popper, TextFieldProps } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";

const autocompleteService = { current: null };

type Props = {
  label?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string | null;
  inputProps?: TextFieldProps;
};

export default function LocationAutocomplete({
  label = "Location",
  onChange = () => {
    // do nothing
  },
  required = false,
  value = null,
  inputProps,
  ...props
}: Props) {
  const [internalValue, setInternalValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [sessionToken] = useState(
    new window.google.maps.places.AutocompleteSessionToken()
  );

  const internalLabel = `${label}${required ? "*" : ""}`;

  // Load place details if placeId is provided
  if (value && internalValue?.place_id !== value) {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails(
      {
        placeId: value,
        fields: ["formatted_address", "place_id"],
      },
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setInternalValue({
            description: result.formatted_address,
            place_id: result.place_id,
          });
        } else {
          console.error(`Place ${value} was not found with status ${status}`);
        }
      }
    );
  }

  const getPlacePredictions = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (newInputValue === "") {
      setOptions(internalValue ? [internalValue] : []);
      return undefined;
    }

    getPlacePredictions(
      {
        input: newInputValue,
        types: ["(cities)"],
        sessionToken,
      },
      (results: google.maps.places.AutocompletePrediction[]) =>
        setOptions(results || [])
    );
  };

  const handleLocationSelect = async (_, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setInternalValue(newValue);
    onChange(newValue?.place_id || null);
  };

  return (
    <Autocomplete
      autoComplete
      filterSelectedOptions
      PaperComponent={({ children, ...paperProps }) => (
        <Paper {...paperProps}>
          {children}
          <Box display="flex" justifyContent="flex-end" p={1}>
            <img
              alt="Powered by Google"
              src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
              width={120}
            />
          </Box>
        </Paper>
      )}
      PopperComponent={({ children, ...popperProps }) =>
        options?.length ? <Popper {...popperProps}>{children}</Popper> : null
      }
      filterOptions={(x) => x}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      isOptionEqualToValue={(opt, val) => opt.place_id === val.place_id}
      noOptionsText=""
      options={options}
      renderInput={(params) => (
        <TextField
          {...inputProps}
          {...params}
          fullWidth
          label={internalLabel}
          placeholder="Start typing to find your city"
          size="small"
        />
      )}
      renderOption={(optionProps, option) => {
        return (
          <li {...optionProps}>
            <Grid container alignItems="center">
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Typography variant="body2">{option.description}</Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      value={internalValue}
      onChange={handleLocationSelect}
      onInputChange={handleInputChange}
      {...props}
    />
  );
}
